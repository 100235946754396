import styled, { css } from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';

export const sectionStyles = background => css`
  background-color: ${background || colors.base.white};
`;

export const AccordionStyles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 30px;

  ${atMinWidth.md`
    flex-direction: row;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 24px;
  width: 100%;

  ${atMinWidth.md`
    align-items: flex-start;
    max-width: 370px;
    height: fit-content;
    position: sticky;
    top: 128px;
  `}
`;

export const AccordionColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  gap: 16px;
  width: 100%;

  ${atMinWidth.md`
    max-width: 670px;
  `}
`;
